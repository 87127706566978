import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { EMPTY, from, Observable, of, Subscription } from 'rxjs';
import { first, tap, mergeMap, map, catchError } from 'rxjs/operators';
import { CapabilityActions } from 'src/app/store/capability/capability.action';
import { CapabilityQuery } from 'src/app/store/capability/capability.query';
import { StateActions } from 'src/app/store/state/state.action';
import { StateQuery } from 'src/app/store/state/state.query';
import { UserAccountAction } from 'src/app/store/userAccount/user-account.action';
import { UserAccountQuery } from 'src/app/store/userAccount/user-account.query';
import { VendorActions } from 'src/app/store/vendor/vendor.action';
import {
  IVendorLocation,
  IVendorOnboardingResult,
  IVendorOnboardingSave,
  mockVendorLocation
} from 'src/app/store/vendor/vendor.model';
import { VendorQuery } from 'src/app/store/vendor/vendor.query';
import {
  OktaService,
  LoggerDatadogService,
  InteractionsApi,
  IdentityApi,
  MapboxService,
  parseAddress
} from 'sustainment-component';
import {
  ICapabilitytProcess,
  ICapability,
  SupplierListModel,
  VendorCreateRequest,
  IVendorProcess,
  IVendorIndustry
} from 'sustainment-component';
import { OnboardingAPI } from 'src/app/api/onboarding.api';
import { AuthService } from 'src/app/services/auth.service';
@Component({
    selector: 'app-registration-container',
    styleUrls: ['./registration.container.scss'],
    templateUrl: './registration.container.html',
    providers: [ConfirmationService],
    standalone: false
})
export class RegistrationContainerComponent implements OnInit, OnDestroy {
  public vendorInfo: Partial<IVendorLocation>;

  public processes: ICapabilitytProcess[] = [];
  public industries: ICapabilitytProcess[] = [];
  public preloadVendor$: Observable<SupplierListModel | null>;
  public preloadVendor: SupplierListModel | null;
  public loaded = false;
  public isSaved = false;
  public isNotBuyer = false;
  public sustainmentid: string;

  public originalProcesses: ICapabilitytProcess[] = [];

  private _subscriptions = new Subscription();
  private _localStorageVariable = 'vendor-info';
  private _refCode$: Observable<string | null>;
  public refCode: string | null;
  public autoCompleteOnboarding = false;

  public subprocessSet: Set<string> = new Set<string>();
  public userRoles$: Observable<import('sustainment-component').UserRole[]>;

  public constructor(
    private capabilityActions: CapabilityActions,
    private userAccountAction: UserAccountAction,
    private userAccountQuery: UserAccountQuery,
    private okta: OktaService,
    private vendorActions: VendorActions,
    private stateQuery: StateQuery,
    private stateAction: StateActions,
    private vendorQuery: VendorQuery,
    private router: Router,
    private capabilityQuery: CapabilityQuery,
    private interactionsApi: InteractionsApi,
    private identityApi: IdentityApi,
    public loggerDatadogService: LoggerDatadogService,
    private confirmationService: ConfirmationService,
    private _onboardingApi: OnboardingAPI,
    private _authService: AuthService,
    private _mapSvc: MapboxService
  ) {}

  public ngOnInit(): void {
    this.userRoles$ = this.identityApi.getUserRoles();
    this.vendorActions.getVendorData();
    this.vendorQuery
      .select((v) => v.vendor)
      .pipe(first())
      .subscribe((v) => {
        if (v?.name && this.router.url.includes('registration')) {
          this.isSaved = true;
          this.router.navigate(['/dashboard']);
        }
      });

    this._refCode$ = from(this.okta.widget.authClient.getUser()).pipe(
      map((user) => user.ref?.toString() || null)
    );

    const invite$ = this._refCode$.pipe(
      mergeMap((code) =>
        code ? this.interactionsApi.getOdysseus(code.toString()) : of(null)
      )
    );

    this._subscriptions.add(
      this._refCode$.subscribe((code) => (this.refCode = code))
    );

    this.preloadVendor$ = invite$.pipe(
      catchError(() => of(null)),
      tap(
        (odysseus: { stillValid?: boolean; sustainmentId: string } | null) => {
          if (odysseus && !odysseus.stillValid)
            this.confirmationService.confirm({
              key: 'cancelOnboardingPreselection',
              header: 'Register your own business',
              message:
                'This invite seems to have already been used. ' +
                'You can register your business to connect and work with others. ' +
                'If you do not wish to proceed, please close the following window.',
              acceptLabel: 'Create my profile',
              acceptIcon: 'null',
              rejectVisible: false,
              accept: () => {
                this.preloadVendor$ = of(null);
              }
            });
        }
      ),
      mergeMap(
        (result: { stillValid?: boolean; sustainmentId: string } | null) =>
          result?.stillValid
            ? this._onboardingApi.getOpensearchVendor(result.sustainmentId)
            : of(null)
      ),
      catchError(() => of(null))
    );

    this._subscriptions.add(
      this._refCode$
        .pipe(
          mergeMap(() => this.preloadVendor$),
          tap((preload) => {
            this.preloadVendor = preload;
            if (this.refCode && preload) {
              this.vendorInfo = this.mapSupplierListToVendorLocation(preload);

              this._mapSvc
                .getGeocodingWithType(
                  this.vendorInfo.primaryAddress as string,
                  'address',
                  true
                )
                .subscribe((r) => {
                  const mapSearchRes = r?.features[0];
                  const parsedAddress = mapSearchRes?.place_name
                    ? parseAddress(mapSearchRes)
                    : null;

                  if (parsedAddress && this.stateQuery.getValue().states) {
                    this.vendorInfo.address = {
                      ...parsedAddress,
                      stateId: Number(
                        this.stateQuery.getByNameOrAbbreviation(
                          parsedAddress.stateAbbreviation || 'OU'
                        )?.stateId
                      )
                    };
                  }

                  this.loaded = true;
                });
              this.autoCompleteOnboarding = true;
            } else {
              this.loaded = true;
            }
          })
        )
        .subscribe()
    );

    const storageSaved = localStorage.getItem(this._localStorageVariable);
    const sustainmentId = this.userAccountQuery.sustainmentId;
    if (storageSaved) {
      this.vendorInfo = JSON.parse(storageSaved);
      this.loggerDatadogService.info('onboarding resumed', {
        sustainmentId,
        data: this.vendorInfo,
        action: 'onboarding'
      });
    } else {
      this.loggerDatadogService.info('started onboarding from scratch', {
        sustainmentId,
        action: 'onboarding'
      });
      this.vendorInfo = { ...mockVendorLocation };
    }
    this.stateAction.getStateData();
    this.loadIndustryAndProcesess();
  }

  public logout(): void {
    this._authService.logout();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public onFinishRegistration(
    data: IVendorOnboardingResult,
    roles?: { id: number; text: string }[],
    problems?: { id: number; text: string }[]
  ): void {
    if (this.preloadVendor)
      data.sustainmentId = this.preloadVendor.sustainmentId;

    this.preloadVendor$ = of(null);
    this.loaded = false;

    const industries = data.industries.map((i) => ({
      id: i.industry.id,
      orderIndex: 0,
      isFavorite: i.isFavorite || false
    }));

    let processes = data.processes;

    if (processes?.length > 0) {
      processes = processes.map((p) => {
        const process = p as { code: string; name: string; isNew: boolean };

        return {
          processCode: process.code,
          name: process.name,
          isCustom: process.isNew
        };
      });
    }

    const createVendor: VendorCreateRequest = {
      ...data,
      primaryAddress: {
        ...data.primaryAddress,
        vendorAddressId: 0,
        unit: '',
        stateId: 0
      },
      industries,
      processes: processes as { id: number; orderIndex: number }[]
    };

    const vendorState = this.stateQuery.getByNameOrAbbreviation(
      data.primaryAddress.state || 'OU'
    );

    if (vendorState) createVendor.primaryAddress.stateId = vendorState?.stateId;

    this.vendorActions.createVendorV3(createVendor).subscribe(() => {
      this.userAccountAction.showedWelcome();
      this._refCode$
        .pipe(
          mergeMap((code) => {
            if (code)
              return this.interactionsApi.acceptInvitation(
                code,
                createVendor.sustainmentId
              );
            if (createVendor && createVendor.sustainmentId)
              return this.interactionsApi.checkOtherInvitations(
                createVendor.sustainmentId
              );
            else return EMPTY;
          })
        )
        .subscribe({
          next: () =>
            this.loggerDatadogService.info('invitation accepted', {
              data: createVendor,
              action: 'onboarding'
            }),
          error: (error) =>
            this.loggerDatadogService.error('failed to accept invitation', {
              data: createVendor,
              action: 'onboarding',
              error
            })
        });

      this.vendorQuery
        .select()
        .pipe(
          first((v) => !!v?.vendor?.name),
          mergeMap(() => this._refCode$),
          tap((code) => {
            return code?.toLowerCase().startsWith('pr')
              ? this.router.navigate(['/opportunities'])
              : data.isBuyer
                ? this.router.navigate(['/home'])
                : '';
          })
        )
        .subscribe(() => {
          this.identityApi
            .updateOrganizationName(
              createVendor.name,
              this.userAccountQuery.sustainmentId
            )
            .subscribe();

          if (roles?.length) {
            this.identityApi
              .updateUserRoles(
                this.userAccountQuery.sustainmentId,
                roles.map((e) => e.id)
              )
              .subscribe();
            this.userAccountAction.updateUserRoles(roles.map((e) => e.text));
          }

          if (problems?.length) {
            this.userAccountAction.updateUserProblems(problems);
          }
          this.loaded = true;
          this.isSaved = true;
          this.isNotBuyer = createVendor.isSupplier || createVendor.isOther;
          this.sustainmentid = createVendor.sustainmentId;
          localStorage.removeItem(this._localStorageVariable);
        });
    });
  }

  public loadIndustryAndProcesess(): void {
    this.capabilityActions.getCapabilityData();
    this._subscriptions.add(
      this.capabilityQuery.select().subscribe((capabilities) => {
        this.originalProcesses = capabilities.processList;
        this.processes =
          capabilities.processList?.map((process) => {
            const using = this.vendorInfo.processes?.find(
              (vendorProcess) => vendorProcess.process.code === process.code
            );

            const subprocessListTemp: ICapability[] = [];
            process.subprocess?.forEach((subprocess: ICapability) => {
              const tempSubprocess: ICapability = {
                name: subprocess.name,
                code: subprocess.code,
                description: subprocess.description,
                using: this.subprocessSet.has(subprocess.code),
                id: subprocess.id
              };
              subprocessListTemp.push(tempSubprocess);
            });

            return {
              name: process.name,
              code: process.code,
              description: process.description,
              using: !!using,
              favorite: !!using && using.isFavorite,
              id: process.id,
              subprocess: subprocessListTemp
            };
          }) || [];

        this.industries =
          capabilities.industryList?.map((industry) => {
            const using = this.vendorInfo.industries?.find(
              (vendorIndustry) => vendorIndustry.industry.code === industry.code
            );
            return {
              name: industry.name,
              code: industry.code,
              description: industry.description,
              using: !!using,
              favorite: !!using && using.isFavorite,
              id: industry.id
            };
          }) || [];
      })
    );
  }

  public updateVendorInfo(data: IVendorOnboardingSave): void {
    this.userAccountAction.updateOrganizationName(data.name);
    const vendorInfoJson = this.getJsonVendor(data);
    localStorage.setItem(this._localStorageVariable, vendorInfoJson);
  }

  public cancelPreselection(): void {
    this.confirmationService.confirm({
      key: 'cancelOnboardingPreselection',
      header: 'Register your own business',
      message:
        'You can register your own business to connect and work with others. ' +
        'If you do not wish to proceed, please close the following window.',
      acceptLabel: 'Create my profile',
      acceptIcon: 'null',
      rejectVisible: false,
      accept: () => {
        this.preloadVendor$ = of(null);
      }
    });
  }

  public requestToJoinCompany(company: SupplierListModel): void {
    if (!company.isRegistered) return;
    this.identityApi
      .requestToJoinCompany(company.sustainmentId)
      .subscribe(() =>
        this.router.navigateByUrl('/pending-activation', {
          state: { companyName: company.name }
        })
      );
  }

  private getJsonVendor(data: IVendorOnboardingSave): string {
    const formSaved = {
      name: data.name,
      address: data.address,
      processes: [],
      industries: [],
      website: data.website
    };

    return JSON.stringify(formSaved);
  }

  private mapSupplierListToVendorLocation(
    supplier: SupplierListModel
  ): Partial<IVendorLocation> {
    return {
      sustainmentId: supplier.sustainmentId,
      name: supplier.name,
      descriptionPlaceholder: supplier.description,
      primaryAddress:
        supplier.address.addressLine +
        ', ' +
        supplier.address.city +
        ', ' +
        supplier.address.state +
        ' ' +
        supplier.address.zip,

      website: supplier.website,
      processes: supplier.processes as unknown as IVendorProcess[],
      industries: supplier.industries as unknown as IVendorIndustry[]
    };
  }
}
